import React from 'react';
import gitIcon from '../assets/icons/github.svg';
import linkedinIcon from '../assets/icons/linkedin.svg';
import emailIcon from '../assets/icons/envelope.svg';

export const ConnectData = [
    {
        title: 'Email',
        path: 'mailto:itscynthia91@gmail.com',
        icon: <img src={emailIcon} alt="email me"/>,
        cName: 'connect-item'
    },
    {
        title: 'LinkedIn',
        path: 'https://www.linkedin.com/in/itscynthia91/',
        icon: <img src={linkedinIcon} alt="linkedin"/>,
        cName: 'connect-item'
    },
    {
        title: 'GitHub',
        path: 'https://github.com/itscynthia91',
        icon: <img src={gitIcon} alt="github"/>,
        cName: 'connect-item'
    },
];