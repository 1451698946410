import React from "react";
import { Routes, Route, useLocation} from "react-router-dom";
import {AnimatePresence} from 'framer-motion';
//Components
// import Header from '../components/Header';
import Navbar from '../components/navigation/Navbar';
//Pages
import PageHome from '../pages/PageHome';
import PageAbout from '../pages/PageAbout';
import PageSingleProject from '../pages/PageSingleProject';
import PageNotFound from '../pages/PageNotFound';

function AppRouter() {

   const location = useLocation();
  
  return (
 
    <div className="site" id="home">

      {/* <Header /> */}
      <Navbar />
        <main id="site-main" className="site-main">
            <div className="wrapper">
              <AnimatePresence exitBeforeEnter initial={false} >
                <Routes location ={ location } key={location.pathname}>
                <Route path="/" element={<PageHome/>} />
                <Route path="/about" element={<PageAbout/>} />
                <Route path="/:slug" element={<PageSingleProject/>} />
                <Route path="*" element={<PageNotFound/>} />
                </Routes>
              </AnimatePresence>  
            </div>
        </main>

        </div>
  );
}

export default AppRouter;