import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { motion } from 'framer-motion';
import { appTitle } from '../globals/globals';
import { ProjectData } from '../data/ProjectData';
import 'react-tabs/style/react-tabs.css';
// import Logo from '../components/Logo';

function PageSingleProject() {

  const { slug } =  useParams();
  const [project, setProject] = useState(null);
  useEffect(() => {
    const data =ProjectData.filter((item) => item.slug === slug);  
    if (data !== null) {
        setProject(data[0]);
        document.title = `${appTitle} - ${data[0].title}`;
        window.scrollTo(0, 0)
    }
    
  },[slug]);

  return (
    <>
    {/* <Logo/> */}
    <motion.div     
    initial = {{ scaleY: 0 }} 
    animate={{ scaleY: 1}} 
    exit={{ opacity: 0}}>   
    
        {project !== null && (
          <div className="indiv-project-page split-section">
            <section className="indiv-project dark">
              <div className="indiv-project-info">
                {project.title && <h1 className="indiv-project-title">{project.title}</h1> }
                {project.desktopImg && <img className="desktopimg" src={project.desktopImg} alt={project.title}/> }
                <p>{project.overview}</p>
                <div className="indiv-project-links">
                  <a className="cta-btn gradient" href={project.liveUrl} target="_blank" rel="noopener noreferrer"><span>{project.liveText}</span></a>
                  {project.gitUrl && <a className="cta-btn gradient" href={project.gitUrl} target="_blank" rel="noopener noreferrer"><span>{project.gitText}</span></a> }
                  </div> 
              </div>  
               
            </section>
            <div className="project-slice right-side speckle table">
              <div className="tabs-container">
                <Tabs>
                  <TabList>
                    <Tab><h5>Development</h5></Tab>
                    <Tab><h5>Design</h5></Tab>
                    <Tab><h5>Reflection</h5></Tab>
                  </TabList>
                  <TabPanel>
                    <h3>Timeline: </h3><p>{project.timeline}</p>
                    <h3>Toolkit: </h3><p>{project.toolkit}</p>
                    <h3>Role: </h3><p>{project.role}</p>
                    <h3>Process: </h3>
                    <p>{project.process}</p>
                    <h3>Functionality: </h3>
                    <p>{project.functionality}</p>
                  </TabPanel>
                  <TabPanel>
                    <p>{project.design}</p>
                    {project.styleGuide && 
                    
                    <div className="styleguide">
                      {project.styleGuide.map((s, index) => 
                      <div className="guide-box" key={index}>
                      <div className="color" key={index} style={{backgroundColor: `${s}`}}></div><p>{s}</p></div>
                        )}
                    </div> }
                    <div className='project-designs'>
                      {project.siteImg.map((p, index) => 
                      <img className={project.cName} key={index} src={p} alt={project.title} />
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <p>{project.reflection}</p>
                  </TabPanel>
                </Tabs>
              </div> 
            </div>
          </div>
          )
          }
    </motion.div>
    </>
  )
}
export default PageSingleProject;