//Nav
import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { ConnectData } from '../../data/ConnectData';

import homeIcon from '../../assets/icons/house-chimney.svg';
import aboutIcon from '../../assets/icons/girl.svg';
import projectIcon from '../../assets/icons/projectIcon.svg';
import commentIcon from '../../assets/icons/comment.svg';


function Navbar() {
  const [navOpen, setNavOpen] = useState(false);
    
  const toggle = () => {
      setNavOpen(!navOpen);
  }

  const hide = () => setNavOpen(false);
  const show =() => setNavOpen(true);

  return (
    <>
      <nav className="navbar">
        <div className="nav-menu">
          <Link className="nav-item" to ="/"><img src={homeIcon} alt="ceejay design logo"/><span>home</span></Link>
          <Link className="nav-item" to = "/about"><img src={aboutIcon} alt="about me"/><span>About Me</span></Link>
          <Link className="nav-item" to= "/#projects"><img src={projectIcon} alt="projects"/><span>Projects</span></Link>
          <button className="nav-item connect-btn" onClick={toggle}><img src={commentIcon} alt="let's connect"/><span>Let's Connect</span></button>
        </div>
      </nav>
      <div className={navOpen ? 'connect-menu active' : 'connect-menu'}>
        <ul className='connect-menu-items' onClick={toggle} onBlur={hide} onFocus={show}>
          {ConnectData.map((item, index) => {
          return (
          <li key={index} className={item.cName}>
            <a href={item.path} target="_blank" rel="noopener noreferrer">
              {item.icon}
              <span>{item.title}</span>
            </a>
          </li>
          )
          })}
        </ul>
        </div>
    </>
  )
}

export default Navbar;
