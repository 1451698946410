import React from 'react';
import { Link } from "react-router-dom";
// import { motion } from "framer-motion";
import { ProjectData } from '../../data/ProjectData';

const indivProject = [
  {
    image: ProjectData[0].desktopImg,
    title: ProjectData[0].title,
    overview: ProjectData[0].overview,
    id: ProjectData[0].slug
},
{
  image: ProjectData[1].desktopImg,
    title: ProjectData[1].title,
    overview: ProjectData[1].overview,
    id: ProjectData[1].slug
},
{
  image: ProjectData[2].desktopImg,
    title: ProjectData[2].title,
    overview: ProjectData[2].overview,
    id: ProjectData[2].slug
},
{
  image: ProjectData[3].desktopImg,
    title: ProjectData[3].title,
    overview: ProjectData[3].overview,
    id: ProjectData[3].slug
},
{
  image: ProjectData[4].desktopImg,
    title: ProjectData[4].title,
    overview: ProjectData[4].overview,
    id: ProjectData[4].slug
},

]

function AllProjects() {


  return (
    <article 
    className="project-wrapper">
      <h2 className="project-title">Projects</h2>
    <div className="projects-wrapper">
      {indivProject.map((indivProject, index ) => (
      <div key={index} className="project-banner glass-container">
          <div className="project-img">
            <img src={indivProject.image} alt={indivProject.title}/>
          </div>
          <div className="project-info">
            <h3 className="intro-dark">{indivProject.title}</h3>
            <p>{indivProject.overview}</p>
            <Link to={`${indivProject.id}`}>
              <button className="cta-btn gradient">More Info</button>
            </Link>
          </div>
      </div>
      
      ))}

    </div>
    </article>
  )
}

export default AllProjects;