import React from 'react';
import Banner from '../home/Banner';
import memojiSmile from '../../assets/icons/memoji-smile.PNG';
// import Logo from '../Logo';

function HomeHeader() {
  
  return (
    <section className="page-intro full-page dark">
      <Banner />
      <img className="memoji-smile" src={memojiSmile} alt="memoji"/>
      {/* <Logo /> */}
    </section>
  )
}

export default HomeHeader;