// Page - Not Found

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { appTitle } from '../globals/globals';

const PageNotFound = () => {

	useEffect(() => {
		document.title = `${appTitle} - Page Not Found`;
	}, []);
    
	return (
		<section className="pnf-page glass-container">
			<h1 className="intro-dark">404</h1>
			<p>Page not found.</p>
			<p>Go to <Link to="/">Home</Link> page.</p>
		</section>
	);
	
};

export default PageNotFound;