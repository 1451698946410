// import React from 'react';

export const ProjectData = [
    {
        desktopImg: require('../assets/projects/mbbDesktopScreen.png'),
        title: 'Meraki Beauty Bar',
        slug: 'meraki-beauty-bar',
        overview: 'Meraki Beauty Bar is a Cosmetic Tattoo and Lash Bar located in Burnaby BC. Customers book appointments online to this one stop shop for their beauty needs.',
        timeline: 'Eight Weeks',
        toolkit: 'WordPress, PHP, CSS/SASS, Adobe XD ',
        role: 'Developement Planner, UX/UI Designer, Front-End Developer, QA Engineer',
        process: 'This website is for a small business located in Burnaby. I used to work there as a Lash Technician, therefore I knew exactly what the owner wanted and needed. With my new knowledge, She trusted me to build and use what ever platform I wanted so I chose WordPress. This would make the monthly updates for the owner convenient due to the custom pages and post types. I stayed organized by sharing a Google Docs with the employer and was kept multiple files for each Service with the content.',
        functionality:'Using Underscores based theme, I created a custom theme to provide custom post types such as Services, Staff, and Education. Providing content on pages and using ACFs (Advanced Custom Fields), I outputted the information using WP Query within the theme. I customized the admin dashboard to minimize confusion of direction for the editor. This website required a lot of information for their customers. After going over the design, I suggested to go with an accordian to minimize the length of the page and to provide a convenient drop-down to minimize scrolling.',
        design:'This website required multiple pages with repetitive content. To save time, I provided the employer with two low-fidelity wireframes, one to showcase the front page, and one to showcase one of the services. As you can see, the page is extensive, that is why the accordian was desirable.',
        reflection:'WordPress is a CMS platform I enjoy working with. I quickly became comfortable with building the pages and creating post types and ACFs. I am happy I went with WordPress because I know as a small business, there is room for growth, and this platform allows me to easily expand.',
        cName: 'mbb-img',
        siteImg: [
            require('../assets/projects/mbbDesktopHome.jpg'),
            require('../assets/projects/mbbDesktopEyebrows.jpg'),
        ],
        styleGuide: [
            '#ECC975',
            '#F5E5C4',
            '#FFFFFF',
            '#121212',
        ],
        liveUrl: 'https://www.merakibeautybar.ca/',
        liveText: 'Live Site',
        gitUrl: '',
        gitText: '',
        page: '/projects/meraki-beauty-bar',
    },
    {
        desktopImg: require('../assets/projects/portfoliov1Desktop.png'),
        title: 'Portfolio V1',
        slug: 'portfolio',
        overview: 'A personal porfolio site developed using React, to help showcase projects I have accomplished on my journey in becoming a better web developer.',
        timeline: 'Four Weeks',
        toolkit: 'React, HTML, CSS/SASS, Framer Motion',
        role: 'Front-end Web Developer, UX/UI designer, QA Tester',
        process: 'The goal of V1 was to create a easy to navigate web application that would reflect who I am and what I can accomplish. With a short time frame, I chose to use React to help warm me up for the real world. I prepared myself by looking at many portfolios to gain inspiration to decide what functions I liked and what appealed to me. I used Notion to create an organized list of pages along with the content.',
        functionality:'The main purpose of this portfolio is to show my accomplishments (my projects). To create a list of my projects on the main page with clickable links that would send the user to a single project page with a table of information. Creating a prop called "ProjectData", I was able to loop through each project to output information such as an image, overview, and a "slug" that would connect a button to a specific page. On the SingleProjectPage, I decide to use React Tables to create tabs that allowed the user to switch the content from Development, Design and Reflect. I chose this function because I had a hard time deciding whether I wanted to showcase my coding or design skills more since I enjoy both.',
        design: 'I started the design by creating a mobile and desktop prototype. I focused on having only one main accent color. Using different textures such as the speckled background and the "glass-like" background, this helped separate different sections and components.',
        reflection:'With every project, I will gain knowledge to make my next project even better. I am currently working on Portfolio V2 where I will be adding additional functions and pages to help guide the user to the second purpose -- to contact me. Once I have my wireframes done with these minor tweeks, the goal is to create a Headless CMS using WordPress and React, with GSAP animation. ',
        cName: 'gsf-img',
        siteImg: [
            require('../assets/corallogoCJD.png'),

        ],
        styleGuide: [
            '#252525',
            '#ED5D42',
            '#F89B89',
            '#FFFFFF',
        ],
        liveUrl: 'https://ceejay.design/',
        liveText: 'Live Site',
        gitUrl: '',
        gitText: '',
        page: '/projects/portfolio',
    },
    {
        desktopImg: require('../assets/projects/stDesktopScreen.png'),
        title: 'Sacred Tree Massage',
        slug: 'sacred-tree',
        overview: 'Sacred Tree Massage is a fictional e-commerce website built with WordPress and WooCommerce, which allows online appointment bookings. ',
        timeline: 'Four Weeks',
        toolkit: 'WordPress, Underscores based theme, WooCommerce (Bookings and Availability Plug-in), Google Map API, PHP, SASS, HTML',
        role: 'Our team shared responsibilities for design, development, WooCommerce configuration and project management',
        process: 'Our team performed a competitive analysis, and compiled a list of fetures that would create an informative and intuitive website. We created out information architecture, as well as wireframes for each page using Adobe XD. Then created a list of our required php files and custom post types to allow us to efficiently set up and get started.',
        functionality:'The key function of this website is to allow the user to book a massage therapy appointment with a specific therapist, treatment, time, and date. We created a "Book Now" button on the header to allow easy and noticable access.',
        design:'With the requirement to create a warm, welcoming website, we were inspired by the colors of eucalyptus which is commonly thought to aid in relaxation. Our light green accent color which pairs with a light tan and white to provide a feeling of tranquility.',
        reflection:'There were unexpected events that caused the team to be unable to work in the same room, but the availability of Slack and Zoom calls helped us stay connected. We used Github to share our repository and made it a priority to communicate to confirm when pages were pushed, merged, and when changes were made on the live WordPress site. It is also always good to have a open-mind when it comes to design because everyone has there preferences.',
        cName: 'gsf-img',
        siteImg: [
            require('../assets/projects/stmLogocrop.png'),
            require('../assets/projects/stmLogoFlat.png'),
        ],
        styleGuide: [
            '#4C5A5D',
            '#7C918A',
            '#CBDAD6',
            '#FAF8F4',
            '#222222',
        ],
        liveUrl: 'https://ceejay.design/sacredtree',
        liveText: 'Live Site',
        gitUrl: '',
        gitText: '',
        page: '/projects/sacred-tree',
    },
    {
        desktopImg: require('../assets/projects/gsfDesktopScreen.png'),
        title: 'Gold Star Films',
        slug: 'gold-star-films',
        overview: 'Gold Star Films is a single page React web app that enables users to find up-to-date movies and store them to their “favourites”. Movies can be viewed based on Top Rated, Upcoming, Popular, and Now Playing. ',
        timeline: 'Five Weeks',
        toolkit: 'React, MovieDB API, HTML, CSS/SASS, Adobe Photoshop, Adobe XD ',
        role: 'Content and Developement Planner, UI Designer, Front-End Developer, Debug and Tester',
        process: 'First step was to research and plan out the project. This gave me ideas on how to organize the information so the development would go smooth. I used the API from TheMovieDB to retreive the content.',
        functionality:'For the functionality, I decided to use CSS grid to guarentee that the sizes of the posters would be fluid depending on the size of the screen. I used react-router to create a fast and smooth web flow. A way to organize the movies was to create a dropdown menu that would showcase the movies depending on Top Rated, Upcoming, Popular, and Now Playing Movies. This helped provide more whitespace to help the design.',
        design:'Below are the design elements and high-fidelity wireframes for the movie database created on Adobe XD and Illustrator. (Style guide, logo, desktop wireframe, mobile wireframe, singles movie page)',
        reflection:'I may have pushed myself to early in the course to work on a project independently. Even with all my planning, I did not properly deliver the Singles Movie Page as desired. I plan on redoing this project because practice makes perfect! ',
        cName: 'gsf-img',
        siteImg: [
            require('../assets/projects/gsfLogo.png'),
            require('../assets/projects/gsfDesktopWF.jpg'),
            require('../assets/projects/gsfMobileWF.jpg'),
            require('../assets/projects/gsfsingleDesktopWF.jpg'),
        ],
        styleGuide: [
            '#5C5C76',
            '#1C1C24',
            '#F2BF1A',
            '#6B5C1F',
        ],
        liveUrl: 'https://ceejay.design/gold-star-films',
        liveText: 'Live Site',
        gitUrl: 'https://github.com/itscynthia91/movie-database-jan1',
        gitText: 'Github Repository',
        page: '/projects/gold-star-films',
    },
    {
        desktopImg: require('../assets/projects/rtbDesktopScreen.png'),
        title: 'Ride the Bus',
        slug: 'ride-the-bus',
        overview: 'Ride the bus is a JavaScript single player card game that requires the user to complete 3 different levels.',
        timeline: 'Four Weeks',
        toolkit: 'JavaScript, HTML, CSS, Bootstrap',
        role: 'Developement Planner, UX/UI Designer, Front-End Developer',
        process: 'Staying organized with the direction of the game is a key in not getting lost. I created a map to outline the road of each transition between links as well as a Google doc which contained a list of each variable and element to make sure I was not missing any components.',
        processImg: [
            require('../assets/projects/singlePlayerIA.jpg'),
        ],
        functionality:'Using Bootstrap to create the buttons and the module, I was able to display the instructions of the game. Jquery was used to hide and show buttons, along with switching between the three questions of "Red or Black", "High or Low", and "Which suit will show up next". ',
        design:'I decided to go old school with a pixelated image of a school bus and a classic card.  created a guide map to help keep me on track with the direction of the game. ',
        reflection:'For my first JavaScript game, I was pleased with the results. This is a fun game to play with friends so I hope to update this game into a two player game in the future.',
        cName: 'rtb-img',
        siteImg: [
            require('../assets/projects/twoPlayerIA.jpg'),
            require('../assets/projects/busCardBack.png'),
            require('../assets/projects/rtbInstructions.png'),
        ],
        styleGuide: '',
        liveUrl: 'https://ceejay.design/ride-the-bus',
        liveText: 'Live Site',
        githubUrl: '',
        githubText: '',
        page: '/projects/ride-the-bus',
    },

];