import React from 'react';
import AboutBanner from '../about/AboutBanner';
import memojiLaptop from '../../assets/icons/memoji-laptop.PNG';
// import Logo from '../Logo';

function AboutHeader() {
  return (
    <section className="page-intro full-page dark">
      <AboutBanner />
      <img className="memoji" src={memojiLaptop} alt="memoji on laptop"/> 
      {/* <Logo /> */}
    </section>
  )
}

export default AboutHeader;