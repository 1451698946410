import React from 'react';
import { useEffect } from 'react';
import { useLocation} from 'react-router-dom';
import { appTitle } from '../globals/globals';
import { motion } from 'framer-motion';
import HomeHeader from '../components/home/HomeHeader';
import AllProjects from '../components/home/AllProjects';

function PageHome() {

    useEffect(() => {
		document.title = `${appTitle} - Front-End Web Developer`;
     //window.scrollTo(0, 0)
	}, []);

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

    
  return (
    <motion.section 
    id="home" className="home-page split-section"
    initial = {{ scaleY: 0 }} 
    animate={{ scaleY: 1}} 
    exit={{ opacity: 0}}
    >
      <HomeHeader />
        <section id="projects" className=" right-side section-slice speckle">
            <AllProjects />
        </section>
    </motion.section>
  )
}

export default PageHome;