import React from 'react';
import { motion } from "framer-motion";

const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

const Banner = (index) => {

  return (
    <motion.div key={index} className='banner' variants={banner}>
      <p className="first-intro">Hi there, I'm</p>
      <motion.h1 
      className="name"
      initial={{ x: '-100vw'}}
      animate= {{ x: 0 }}
      transition={{ delay: 1}}
      >
        Cynthia Lee
      </motion.h1>

        <BannerRowCenter title={"Front-end"} /><span className="space"></span>
      <div className="flex"> 
        <BannerRowCenter title={"Web"} /><span className="space"></span>
        <BannerRowCenter title={"Developer"} />
      </div>
    </motion.div>
  );
};

const AnimatedLetters = ({ title, disabled}) => (
  <motion.span 
    className='row-title'
    variants={disabled ? null : banner}
    initial='initial'
    animate='animate'>
    {[...title].map((letter, index) => (
      <motion.span key={index}
        className='row-letter'
        variants={disabled ? null : letterAni}>
        {letter}
      </motion.span>
    ))}
  </motion.span>
);

const BannerRowCenter = ({ title }) => {
  return (
    <div className={`banner-row`}>
      <motion.div
        initial={{ y: 310 }}
        animate={{ y: 0 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1 }}>
        <AnimatedLetters title={title} />
      </motion.div>
    </div>
  );
};


export default Banner;