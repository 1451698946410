import React from 'react'
import { useEffect } from 'react';
import { appTitle } from '../globals/globals';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import AboutHeader from '../components/about/AboutHeader';
import Cynthia from '../assets/cynthia-wallop.jpg';
import lakeVideo from '../assets/videos/optimizedlakelouise.mp4';

function PageAbout() {

  useEffect(() => {
		document.title = `${appTitle} - About Cynthia`;
    window.scrollTo(0, 0)
	}, []);

  return (
    <motion.section     
    initial = {{ scaleY: 0 }} 
    animate={{ scaleY: 1}} 
    exit={{ opacity: 0}}
    id="about" className="about-page split-section section-slice">
        <AboutHeader/>
        <div className="right-side section-slice">
          <article className="speckle">
              <div className="glass-container content-container ">
                  <h2 className="about-title">My Name is Cynthia...</h2>
                  <div className="flex-row">
                    <img className="cynthiaimg" src={Cynthia} alt="Cynthia Lee - Front-end Web Developer"/>
                    <div className="wrap-section">
                    <p className="intro">I am a Vancouver-based Front-End Web Developer who has a passion for design and curiousity in code. I use my skills to create user-friendly experiences through web applications and am learning to better express my creative thoughts through artwork.</p>
                    <p className="intro">This industry is a never ending learning path which will keep me on my feet. I currently have a full time position as a Junior Developer at an amazing digital marketing agency called <a href="https://www.wallop.ca">Wallop</a> where we help one-of-a-kind travel and hospitality brands convert discerning traveler into loyal guests. </p>
            <p className="intro">When I am not hooked to the web, I can be found playing Mobile Legends, working on my lettering on Procreate, hanging out with my friends and my dog -- Lumiere, or indulging in a good movie or tv show.</p>
                    <div className="about-links">
                      <Link className="cta-btn gradient" to="/#projects">Check out my projects</Link>
                      </div>
                    </div>
                </div>
              </div>
            <div className="glass-container">
              <h2 className="about-title">Toolkit:</h2>
              <div className="skills-content">
                <div className="skill-lang">
                  <ul>
                    <li>HTML</li>
                    <li>CSS, SASS</li>
                    <li>JavaScript/jQuery</li>
                    <li>PHP</li>
                    <li>React</li>
                    <li>CMS, WordPress</li>
                  </ul>
                </div>
                <div className="skill-design">
                  <ul>
                    <li>Adobe Photoshop</li>
                    <li>Adobe XD</li>
                    <li>Adobe Illustrator</li>
                    <li>Procreate</li>
                  </ul>
                </div>
                <div className="skill-other">
                  <ul>
                    <li>Visual Studio</li>
                    <li>Git</li>
                    <li>UX/UI</li>
                  </ul>
                </div>
              </div>  
            </div>  
            <video className="lake-video" controls autoPlay muted loop playsInline>
				      <source src={lakeVideo} type="video/mp4"/>
		        </video>
          </article>
        </div>
    </motion.section>
  )
}

export default PageAbout;